import { Controller } from "stimulus"

export default class extends Controller {
  static values = { projectItemCode: String }

  connect() {
    const project_item_code = this.projectItemCodeValue;
    if (project_item_code) {
      $.ajax({
        type: 'GET',
        dataType: 'script',
        url: '/ui/project_item_code_select/project_detail.js',
        data: { project_item_code }
      });
    }
  }
}
