import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $('#select-courier-recipient-province-code').selectize({
      plugins: ["remove_button"],
      respect_word_boundaries: false,
      onChange: function(value) {
        if (!value.length) return;
        const courier_recipient_city_code_change_url = `/financial_analysis/other_billing_and_inquiries/courier_recipient_city_code_change?courier_recipient_province_code=${encodeURIComponent(value)}`
        $.ajax(courier_recipient_city_code_change_url, {
          dataType: 'script'
        });
      }
    });
  }
}
