import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $('#select-invoice-delivery-method').selectize({
      plugins: ["remove_button"],
      respect_word_boundaries: false,
      onChange: function(value) {
        if (!value.length) return;
        // 财务寄送
        if (value == 20) {
          $('#courier-recipient-panel').collapse('show');
        } else {
          $('#courier-recipient-panel').collapse('hide');
        }
      }
    });
  }
}
