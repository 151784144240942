import { Controller } from "stimulus"
import { average } from "../echart-helper";

let predictContractChartByPerson;

export default class extends Controller {
  connect() {
    predictContractChartByPerson = echarts.init(document.getElementById('predict-contract-chart-by-person'));
    const personXAxis = JSON.parse(this.data.get("person_x_axis"));
    const personTraceProject = JSON.parse(this.data.get("person_trace_project"));
    const personTraceContract = JSON.parse(this.data.get("person_trace_contract"));
    const personToSignedContract = JSON.parse(this.data.get("person_to_signed_contract"));

    const personSum = [];
    for (let index = 0; index < personXAxis.length; ++index) {
      personSum.push(personTraceProject[index]+personTraceContract[index]+personToSignedContract[index]);
    }
    const avgPersonSum = average(personSum);

    const option_by_person = {
        legend: {
            data: ['汇总','跟踪项目','跟踪合同','待签合同'],
            align: 'left'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: 60,
          right: 120,
          top: 50,
          bottom: 60
        },
        toolbox: {
          feature: {
            dataView: {},
            saveAsImage: {
                pixelRatio: 2
            }
          }
        },
        xAxis: {
          data: personXAxis,
          silent: true,
          axisLabel: {
            interval: 0,
            rotate: -40
          },
          splitLine: {
              show: false
          }
        },
        yAxis: [{
          type: 'value',
          name: '跟踪合同额（万元）',
          position: 'left',
          axisLabel: {
            formatter: '{value}万'
          }
        }],
        series: [{
          name: '汇总',
          type: 'line',
          symbol: 'circle',
          symbolSize: 8,
          data: personSum,
          label: {
            show: true,
            position: 'top'
          },
          markLine: {
            label: {
              formatter: '平均汇总金额{c}'
            },
            lineStyle: {
              type: 'solid',
              width: 1
            },
            data: [
              {
                yAxis: avgPersonSum
              }
            ]
          }
        },{
          name: '跟踪项目',
          type: 'bar',
          data: personTraceProject,
          label: {
            show: true,
            position: 'top'
          }
        },{
          name: '跟踪合同',
          type: 'bar',
          data: personTraceContract,
          label: {
            show: true,
            position: 'top'
          }
        },{
          name: '待签合同',
          type: 'bar',
          data: personToSignedContract,
          label: {
            show: true,
            position: 'top'
          }
        }]
    };

    const seriesNameMapping = option_by_person.legend.data;
    function drill_down_person_detail(params) {
      if (params.componentType === 'series') {
        if (params.seriesType === 'bar') {
          const person_name = personXAxis[params.dataIndex];
          const month_name = $('#month_name').val();
          const sent_data = { person_name, month_name, series_name: seriesNameMapping[params.seriesIndex] };
          let drill_down_url = '/report/people_predict_contract/drill_down_person_detail';

          $.ajax(drill_down_url, {
            data: sent_data,
            dataType: 'script'
          });
        }
      }
    }

    predictContractChartByPerson.setOption(option_by_person, false);
    predictContractChartByPerson.on('click', drill_down_person_detail);

    setTimeout(() => {
      predictContractChartByPerson.resize();
    }, 200);
  }

  layout() {
    predictContractChartByPerson.resize();
  }

  disconnect() {
    predictContractChartByPerson.dispose();
  }
}
