import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ]
  static values = {
    inputType: String
  }

  submit(event) {
    const input_value = this.inputTypeValue == 'checkbox' ? event.target.checked : event.target.value;
    const authenticity_token = this.formTarget.elements['authenticity_token'].value;
    $.ajax({
      type: this.formTarget.method,
      dataType: 'script',
      url: this.formTarget.action,
      data: { input_value, authenticity_token }
    });
  }
}
