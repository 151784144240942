import { Controller } from "stimulus"

let receivableAccountDownDatatable;
let receivableAccountDownTableFixedHeader;

export default class extends Controller {
  static values = { pageLength: Number }

  connect() {
    const normalColumns = [
      {"data": "rank", bSortable: false},
      {"data": "crmshort", bSortable: false},
      {"data": "bill"},
      {"data": "recourse_factoring"},
      {"data": "pledge"},
      {"data": "acc_receive"},
      {"data": "not_invoiced"},
      {"data": "bill_rate"},
      {"data": "recourse_factoring_rate"},
      {"data": "pledge_rate"},
      {"data": "acc_receive_rate"},
      {"data": "not_invoiced_rate"},
    ];

    receivableAccountDownDatatable = $('#customer-receivable-account-downpart-datatable').DataTable({
      "processing": true,
      "serverSide": true,
      "pageLength": this.pageLengthValue,
      "autoWidth": false,
      "ajax": $('#customer-receivable-account-downpart-datatable').data('source'),
      "pagingType": "numbers",
      "info": false,
      "columns": normalColumns,
      "dom":
        "<'text-right'<'d-inline-block'B><'d-inline-block ml-5'l>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      "order": [[ 2, 'desc' ]],
      stateSave: true,
      stateSaveCallback: function(settings, data) {
        localStorage.setItem('DataTables_customer-receivable-account-downpart', JSON.stringify(data));
      },
      stateLoadCallback: function(settings) {
        return JSON.parse(localStorage.getItem('DataTables_customer-receivable-account-downpart'));
      }
    });

    function draw_dt() {
      const pageInfo = receivableAccountDownDatatable.page.info();
      receivableAccountDownDatatable.column(0, { page: 'current' }).nodes().each(function (cell, i) {
        cell.innerHTML = i + 1 + pageInfo.start;
      });
    }
    receivableAccountDownDatatable.on('draw.dt', draw_dt);

    receivableAccountDownTableFixedHeader = new $.fn.dataTable.FixedHeader(receivableAccountDownDatatable, {
      header: true,
      footer: false,
      headerOffset: 50,
      footerOffset: 0
    });
  }

  disconnect() {
    receivableAccountDownTableFixedHeader.destroy();
    receivableAccountDownDatatable.destroy();
  }
}
