import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $('#select-company-code').selectize({
      plugins: ["remove_button"],
      respect_word_boundaries: false,
      onChange: function(value) {
        if (!value.length) return;

        const sent_invoice_org_change_url = `/account/cooperation_bill_by_dept/fill_department_code?company_code=${encodeURIComponent(value)}`
        $.ajax(sent_invoice_org_change_url, {
          dataType: 'script'
        });
      }
    });
  }
}
