import { Controller } from "stimulus"

let splitCustomerBillingAmountDatatable;
let splitCustomerBillingAmountHeader;

export default class extends Controller {
  static values = { pageLength: Number }

  connect() {
    const normalColumns = [
      {"data": "rank"},
      {"data": "customer_group"},
      {"data": "is_top_50"},
      {"data": "invoiced_amount_in_past_year"},
      {"data": "attorney_letter_sent_date"},
      {"data": "is_bouncing_ticket"},

      {"data": "bank_transfer"},
      {"data": "no_recovery_factoring"},
      {"data": "bill"},
      {"data": "factoring"},
      {"data": "payment_by_room"},
      {"data": "unknown_payment_method"},

      {"data": "bank_transfer_rate"},
      {"data": "no_recovery_factoring_rate"},
      {"data": "bill_rate"},
      {"data": "factoring_rate"},
      {"data": "payment_by_room_rate"},
    ];

    splitCustomerBillingAmountDatatable = $('#split-customer-billing-amount-datatable').DataTable({
      "processing": true,
      "serverSide": true,
      "pageLength": this.pageLengthValue,
      "autoWidth": false,
      "ajax": $('#split-customer-billing-amount-datatable').data('source'),
      "pagingType": "full_numbers",
      "columns": normalColumns,
      "dom":
        "<'text-right'<'d-inline-block'B><'d-inline-block ml-5'l>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      "order": [[ 5, 'desc' ]],
      stateSave: true,
      stateSaveCallback: function(settings, data) {
        localStorage.setItem('DataTables_split_customer_billing_amount', JSON.stringify(data));
      },
      stateLoadCallback: function(settings) {
        return JSON.parse(localStorage.getItem('DataTables_split_customer_billing_amount'));
      }
    });

    function draw_dt() {
      const pageInfo = splitCustomerBillingAmountDatatable.page.info();
      splitCustomerBillingAmountDatatable.column(0, { page: 'current' }).nodes().each(function (cell, i) {
        cell.innerHTML = i + 1 + pageInfo.start;
      });
    }
    splitCustomerBillingAmountDatatable.on('draw.dt', draw_dt);

    splitCustomerBillingAmountHeader = new $.fn.dataTable.FixedHeader(splitCustomerBillingAmountDatatable, {
      header: true,
      footer: false,
      headerOffset: 50,
      footerOffset: 0
    });
  }

  disconnect() {
    splitCustomerBillingAmountHeader.destroy();
    splitCustomerBillingAmountDatatable.destroy();
  }
}
