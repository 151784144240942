import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    allowRefresh: Boolean,
  }

  connect() {
    const my_controller = this;
    $('#select-sent-invoice-org-custom').selectize({
      plugins: ["remove_button"],
      respect_word_boundaries: false,
      onChange: function(value) {
        if (my_controller.allowRefreshValue == false) return;
        if (!value.length) return;

        const year_name = $('#year_name').val();
        const sent_invoice_org_change_url = `/financial_analysis/production_value_bonus_invoices/sent_invoice_org_change?sent_invoice_org_code=${encodeURIComponent(value)}&year_name=${year_name}`
        my_controller.allowRefreshValue = false;
        $.ajax(sent_invoice_org_change_url, {
          dataType: 'script'
        });
      }
    });
    $('#select-be-billed-org-custom').selectize({
      plugins: ["remove_button"],
      respect_word_boundaries: false,
      onChange: function(value) {
        if (my_controller.allowRefreshValue == false) return;
        if (!value.length) return;

        const year_name = $('#year_name').val();
        const sent_invoice_org_change_url = `/financial_analysis/production_value_bonus_invoices/be_billed_org_change?be_billed_org_code=${encodeURIComponent(value)}&year_name=${year_name}`
        my_controller.allowRefreshValue = false;
        $.ajax(sent_invoice_org_change_url, {
          dataType: 'script'
        });
      }
    });
  }

  swapOrgCustom() {
    const sent = $('#select-sent-invoice-org-custom').val();
    const billed = $('#select-be-billed-org-custom').val();
    this.allowRefreshValue = true;

    $('#select-sent-invoice-org-custom').data('selectize').setValue(billed);
    $('#select-be-billed-org-custom').data('selectize').setValue(sent);
  }

  swapOrg() {
    const sent = $('#select-sent-invoice-org').val();
    const billed = $('#select-be-billed-org').val();
    this.allowRefreshValue = true;

    $('#select-sent-invoice-org').data('selectize').setValue(billed);
    $('#select-be-billed-org').data('selectize').setValue(sent);
  }
}
