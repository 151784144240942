import { Controller } from "stimulus"

let subsidiaryNeedReceiveFactoringFixedHeader;

export default class extends Controller {
  static values = { pageLength: Number }

  connect() {
    const normalColumns = [
      {"data": "contract_no"},
      {"data": "contract_name"},
      {"data": "apply_for_billing_company"},
      {"data": "apply_for_billing_department"},
      {"data": "business_person_in_charge"},
      {"data": "customer_group"},
      {"data": "business_company"},
      {"data": "business_department"},
      {"data": "factoring_fee"},
      {"data": "factoring_date"},
      {"data": "if_paid_by_party_a_and_comment"},
    ];

    const subsidiaryNeedReceiveFactoringDatatable = $('#subsidiary-need-receive-factoring-datatable').dataTable({
      "processing": true,
      "serverSide": true,
      "pageLength": this.pageLengthValue,
      "autoWidth": false,
      "ajax": $('#subsidiary-need-receive-factoring-datatable').data('source'),
      "pagingType": "full_numbers",
      "columns": normalColumns,
      "order": [[ 5, 'desc' ]],
      stateSave: true,
      drawCallback: function () {
          $('button[data-toggle="popover"]').popover({ "html": true });
        },
      stateSaveCallback: function(settings, data) {
          localStorage.setItem('DataTables_subsidiary-need-receive-factoring', JSON.stringify(data));
        },
      stateLoadCallback: function(settings) {
        return JSON.parse(localStorage.getItem('DataTables_subsidiary-need-receive-factoring'));
        }
    });
    subsidiaryNeedReceiveFactoringFixedHeader = new $.fn.dataTable.FixedHeader(subsidiaryNeedReceiveUnsignDetailsDatatable, {
      header: true,
      footer: false,
      headerOffset: 50,
      footerOffset: 0
    });
  }

  disconnect() {
    subsidiaryNeedReceiveFactoringFixedHeader.destroy();
    $('#subsidiary-need-receive-factoring-datatable').DataTable().destroy();
  }
}
