import { Controller } from "stimulus"
import { average, no_Zero_Formater } from "../echart-helper";

let departmentReceiveChartByPersonSummary;
let departmentReceiveChartByPersonCategory;

export default class extends Controller {
  connect() {
    departmentReceiveChartByPersonSummary = echarts.init(document.getElementById('department-receive-chart-by-person-summary'));
    departmentReceiveChartByPersonCategory = echarts.init(document.getElementById('department-receive-chart-by-person-category'));

    const chartDataset = JSON.parse(this.data.get("chart_dataset"));
    const rowCount = chartDataset.length - 1;
    const columnCount = chartDataset[0].length - 1;

    const biTypes = [];
    for (let i = 1; i <= columnCount; i++) {
      biTypes.push(chartDataset[0][i]);
    }

    const personXAxis = [];
    for (let i = 1; i <= rowCount; i++) {
      personXAxis.push(chartDataset[i][0]);
    }

    const personRealAmounts = [];
    for (let i = 1; i <= rowCount; i++) {
      let person_summary = 0;
      for (let j = 1; j <= columnCount; j++) {
        person_summary += chartDataset[i][j];
      }
      personRealAmounts.push(Math.round(person_summary));
    }

    const avgPersonRealAmounts = average(personRealAmounts);

    const biTypeByName = [];
    for (let i = 1; i <= columnCount; i++) {
      let bi_types = [];
      for (let j = 1; j <= rowCount; j++) {
        bi_types.push(chartDataset[j][i]);
      }
      biTypeByName.push({
        name: chartDataset[0][i],
        type: 'bar',
        label: {
          show: true,
          position: 'insideTop',
          formatter: no_Zero_Formater
        },
        data: bi_types,
        barMaxWidth: 38
      });
    }

    const option_by_person_summary = {
        legend: {
            data: ['合同累计收款金额'],
            align: 'left'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: 70,
          right: 120,
          top: 50,
          bottom: 60
        },
        toolbox: {
          feature: {
            dataView: {},
            saveAsImage: {
                pixelRatio: 2
            }
          }
        },
        xAxis: {
          data: personXAxis,
          silent: true,
          axisLabel: {
            interval: 0,
            rotate: -40
          },
          splitLine: {
              show: false
          }
        },
        yAxis: [{
          type: 'value',
          name: '商务人员实收款（万元）',
          position: 'left',
          axisLabel: {
            formatter: '{value}万'
          }
        }],
        series: [{
          name: '合同累计收款金额',
          type: 'bar',
          data: personRealAmounts,
          label: {
            show: true,
            position: 'top'
          },
          markLine: {
            label: {
              formatter: '平均实收款{c}万'
            },
            lineStyle: {
              type: 'solid',
              width: 1
            },
            data: [
              {
                yAxis: avgPersonRealAmounts
              }
            ]
          }
        }]
    };

    const option_by_person_category = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
            pixelRatio: 2
          }
        }
      },
      legend: {
        data: biTypes,
        left: 'center',
        top: '33'
      },
      grid: {
        left: 70,
        right: 120,
        top: 50,
        bottom: 60
      },
      xAxis: {
        type: 'category',
        data: personXAxis,
        axisLabel: {
          interval: 0,
          rotate: -40,
          margin: 20
        },
      },
      yAxis: {
        type: 'value',
        name: '万元',
      },
      series: biTypeByName,
    };

    function drill_down_people_summary(params) {
      if (params.componentType === 'series') {
        if (params.seriesType === 'bar') {
          const person_name = personXAxis[params.dataIndex];
          const org_code = $('#org-code').val();
          const start_month_name = $('#start-month-name').val();
          const end_month_name = $('#end-month-name').val();
          const sent_data = { person_name, org_code, start_month_name, end_month_name };
          let drill_down_url = '/report/people_department_receive/drill_down_people_summary';

          $.ajax(drill_down_url, {
            data: sent_data,
            dataType: 'script'
          });
        }
      }
    }


    departmentReceiveChartByPersonSummary.setOption(option_by_person_summary, false);
    departmentReceiveChartByPersonSummary.on('click', drill_down_people_summary);
    departmentReceiveChartByPersonCategory.setOption(option_by_person_category, false);

    setTimeout(() => {
      departmentReceiveChartByPersonSummary.resize();
      departmentReceiveChartByPersonCategory.resize();
    }, 200);
  }

  layout() {
    departmentReceiveChartByPersonSummary.resize();
    departmentReceiveChartByPersonCategory.resize();
  }

  disconnect() {
    departmentReceiveChartByPersonSummary.dispose();
    departmentReceiveChartByPersonCategory.dispose();
  }
}
