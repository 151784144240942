import { Controller } from "stimulus"

export default class extends Controller {
  static values = { loadUrl: String, changedUrl: String }

  connect() {
    const load_url = this.loadUrlValue;
    const changed_url = this.changedUrlValue;
    $('#labor-monthly-adjust-ncworkno-select').selectize({
      plugins: ["remove_button"],
      respect_word_boundaries: false,
      valueField: "clerk_code",
      labelField: "chinese_name",
      searchField: ['clerk_code','chinese_name'],
      create: false,
      load: function (query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: `${load_url}?q=${encodeURIComponent(query)}`,
          type: "GET",
          error: function () {
            callback();
          },
          success: function (res) {
            callback(res.users);
          },
        });
      },
      onChange: function(value) {
        if (!value.length) return;

        const month_name = $('#month_name').val();
        const clerk_code_changed_url = `${changed_url}?clerk_code=${encodeURIComponent(value)}&month_name=${month_name}`
        $.ajax(clerk_code_changed_url, {
          dataType: 'script'
        });
      }
    });
  }
}
