import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $('#select-sent-invoice-org').selectize({
      plugins: ["remove_button"],
      respect_word_boundaries: false,
      onChange: function(value) {
        if (!value.length) return;

        const sent_invoice_org_change_url = `/financial_analysis/other_billing_and_inquiries/sent_invoice_org_change?sent_invoice_org_code=${encodeURIComponent(value)}`
        $.ajax(sent_invoice_org_change_url, {
          dataType: 'script'
        });
      }
    });
  }
}
