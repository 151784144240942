import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "gradeM", "grade1", "grade2", "grade3", "grade4", "grade5" ]

  input(event) {
    const all_free = event.target.checked;
    if(all_free) {
      this.gradeMTarget.checked = true;
      this.grade1Target.checked = true;
      this.grade2Target.checked = true;
      this.grade3Target.checked = true;
      this.grade4Target.checked = true;
    } else {
      this.gradeMTarget.checked = false;
      this.grade1Target.checked = false;
      this.grade2Target.checked = false;
      this.grade3Target.checked = false;
      this.grade4Target.checked = false;
    }
  }
}
