import { Controller } from "stimulus"

let tianzhenLoginChart;

export default class extends Controller {
  static targets = [ "chart" ];
  static values = { tzlcTime: Array, tzlcCount: Array, legendName: String };

  connect() {
    tianzhenLoginChart = echarts.init(this.chartTarget);

    const option = {
      title: {
        text: '软件使用统计',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: [this.legendNameValue],
        left: 'center',
        top: '30'
      },
      grid: {
        left: '3%',
        right: '1%',
        top: '60',
        bottom: '20',
        containLabel: true
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
              pixelRatio: 2
          }
        }
      },
      xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.tzlcTimeValue
      },
      yAxis: {
          type: 'value'
      },
      series: [{
        name: this.legendNameValue,
        data: this.tzlcCountValue,
        type: 'line',
        areaStyle: {}
      }]
    };

    tianzhenLoginChart.setOption(option);
  }

  layout() {
    tianzhenLoginChart.resize();
  }

  disconnect() {
    tianzhenLoginChart.dispose();
  }
}
