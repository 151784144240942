import { Controller } from "stimulus"
import { average, no_Zero_Formater } from "../echart-helper";

let contractSigningChartByPerson;
let contractSigningChartByPersonCategory;

export default class extends Controller {
  connect() {
    contractSigningChartByPerson = echarts.init(document.getElementById('contract-signing-chart-by-person'));
    contractSigningChartByPersonCategory = echarts.init(document.getElementById('contract-signing-chart-by-person-category'));

    const personAmountTotals = JSON.parse(this.data.get("person_amount_totals"));
    const personRealAmountTotals = JSON.parse(this.data.get("person_real_amount_totals"));
    const personDate1 = JSON.parse(this.data.get("person_date1"));
    const chartDataset = JSON.parse(this.data.get("dataset"));
    const rowCount = chartDataset.length - 1;
    const columnCount = chartDataset[0].length - 1;

    const biTypes = [];
    for (let i = 1; i <= columnCount; i++) {
      biTypes.push(chartDataset[0][i]);
    }

    const personXAxis = [];
    for (let i = 1; i <= rowCount; i++) {
      personXAxis.push(chartDataset[i][0]);
    }

    const personSum = [];
    for (let index = 0; index < personXAxis.length; ++index) {
      personSum.push(personAmountTotals[index]+personRealAmountTotals[index]);
    }
    const avgPersonSum = average(personSum);

    const personAmounts = [];
    for (let i = 1; i <= rowCount; i++) {
      let person_summary = 0;
      for (let j = 1; j <= columnCount; j++) {
        person_summary += chartDataset[i][j];
      }
      personAmounts.push(Math.round(person_summary));
    }

    const biTypeByName = [];
    for (let i = 1; i <= columnCount; i++) {
      let bi_types = [];
      for (let j = 1; j <= rowCount; j++) {
        bi_types.push(chartDataset[j][i]);
      }
      biTypeByName.push({
        name: chartDataset[0][i],
        type: 'bar',
        label: {
          show: true,
          position: 'insideTop',
          formatter: no_Zero_Formater
        },
        data: bi_types,
        barMaxWidth: 38
      });
    }

    const option_by_person = {
        legend: {
            data: ['预计签约额','实际签约额','签约周期'],
            align: 'left'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: 60,
          right: 120,
          top: 50,
          bottom: 60
        },
        toolbox: {
          feature: {
            dataView: {},
            saveAsImage: {
                pixelRatio: 2
            }
          }
        },
        xAxis: {
          data: personXAxis,
          silent: true,
          axisLabel: {
            interval: 0,
            rotate: -40
          },
          splitLine: {
              show: false
          }
        },
        yAxis: [{
          type: 'value',
          name: '跟踪合同额（万元）',
          position: 'left',
          axisLabel: {
            formatter: '{value}万'
          }
        },{
          type: 'value',
          name: '签约周期（天）',
          position: 'right',
          axisLabel: {
            formatter: '{value}天'
          }
        }],
        series: [{
          name: '签约周期',
          type: 'line',
          yAxisIndex: 1,
          symbol: 'square',
          symbolSize: 8,
          data: personDate1,
          label: {
            show: true,
            position: 'top'
          }
        },{
          name: '预计签约额',
          type: 'bar',
          data: personAmountTotals,
          label: {
            show: true,
            position: 'top'
          }
        },{
          name: '实际签约额',
          type: 'bar',
          data: personRealAmountTotals,
          label: {
            show: true,
            position: 'top'
          },
          markLine: {
            label: {
              formatter: '平均签约额{c}'
            },
            lineStyle: {
              type: 'solid',
              width: 1
            },
            data: [
              {
                yAxis: avgPersonSum
              }
            ]
          }
        }]
    };

    const option_by_person_category = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
            pixelRatio: 2
          }
        }
      },
      legend: {
        data: biTypes,
        left: 'center',
        top: '33'
      },
      grid: {
        left: 70,
        right: 120,
        top: 50,
        bottom: 60
      },
      xAxis: {
        type: 'category',
        data: personXAxis,
        axisLabel: {
          interval: 0,
          rotate: -40,
          margin: 20
        },
      },
      yAxis: {
        type: 'value',
        name: '万元',
      },
      series: biTypeByName,
    };

    function drill_down_person_detail(params) {
      if (params.componentType === 'series') {
        if (params.seriesType === 'bar') {
          const person_name = personXAxis[params.dataIndex];
          const month_name = $('#month_name').val();
          const sent_data = { person_name, month_name };
          let drill_down_url = '/report/people_contract_signing/drill_down_person_detail';

          $.ajax(drill_down_url, {
            data: sent_data,
            dataType: 'script'
          });
        }
      }
    }

    contractSigningChartByPerson.setOption(option_by_person, false);
    contractSigningChartByPerson.on('click', drill_down_person_detail);

    function drill_down_person_category_detail(params) {
      if (params.componentType === 'series') {
        if (params.seriesType === 'bar') {
          const person_name = params.name;
          const bi_type = params.seriesName;
          const month_name = $('#month_name').val();
          const sent_data = { person_name, bi_type, month_name };
          let drill_down_url = '/report/people_contract_signing/drill_down_person_category_detail';

          $.ajax(drill_down_url, {
            data: sent_data,
            dataType: 'script'
          });
        }
      }
    }

    contractSigningChartByPersonCategory.setOption(option_by_person_category, false);
    contractSigningChartByPersonCategory.on('click', drill_down_person_category_detail);

    setTimeout(() => {
      contractSigningChartByPerson.resize();
      contractSigningChartByPersonCategory.resize();
    }, 200);
  }

  layout() {
    contractSigningChartByPerson.resize();
    contractSigningChartByPersonCategory.resize();
  }

  disconnect() {
    contractSigningChartByPerson.dispose();
    contractSigningChartByPersonCategory.dispose();
  }
}
