import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    costAccrualRatio: Number,
    issuedInvoicedAmount: Number,
    remainInvoiceAmount: Number
  }

  numberChanged() {
    const billingMatters = $('#billingMatters').val();
    const saveBtn = $('#new-invoice-approval-save-btn');
    const applicationAmountText = $('#applicationAmount').val();
    const applicationAmount = parseFloat(applicationAmountText);
    const selectedText = $('#taxRate option:selected').text();
    const selectTax = parseFloat(selectedText) / 100.0;
    let applicationAmountWithOrWithoutTax = 0;
    switch (billingMatters) {
      case '子公司间（不含与上海天华）跨年留存产值（含稅）':
      case '产值结算':
        applicationAmountWithOrWithoutTax = applicationAmount * (1+selectTax);
        break;
      default:
        applicationAmountWithOrWithoutTax = applicationAmount;
    }
    console.log("billingMatters", billingMatters);
    console.log("applicationAmountWithOrWithoutTax", applicationAmountWithOrWithoutTax);
    console.log("this.remainInvoiceAmountValue", this.remainInvoiceAmountValue);
    console.log("this.issuedInvoicedAmountValue", this.issuedInvoicedAmountValue);
    console.log("Math.round(this.remainInvoiceAmountValue * 100) >= Math.round(applicationAmountWithOrWithoutTax * 100)", Math.round(this.remainInvoiceAmountValue * 100) >= Math.round(applicationAmountWithOrWithoutTax * 100));
    console.log("Math.round(applicationAmountWithOrWithoutTax * 100) >= 0", Math.round(applicationAmountWithOrWithoutTax * 100) >= 0);
    const text = `
      <p>开票税额：${(applicationAmount*selectTax).toFixed(2)}，价税合计：${(applicationAmount * (1+selectTax)).toFixed(2)}；</p>
      <p>成本计提比例：${this.costAccrualRatioValue}%，成本计提金额：${(applicationAmountWithOrWithoutTax*(this.costAccrualRatioValue / 100.0)).toFixed(2)}。</p>
    `
    $('#calculatedText').html(text);

    if (this.remainInvoiceAmountValue >= 0) {
      if (Math.round(this.remainInvoiceAmountValue * 100) >= Math.round(applicationAmountWithOrWithoutTax * 100)) {
        saveBtn.attr('disabled', false);
      } else {
        saveBtn.attr('disabled', true);
      }
    } else {
      if (0 > Math.round(applicationAmountWithOrWithoutTax * 100) && (Math.round(applicationAmountWithOrWithoutTax * 100) >= -Math.round(this.issuedInvoicedAmountValue * 100))) {
        saveBtn.attr('disabled', false);
      } else {
        saveBtn.attr('disabled', true);
      }
    }
  }
}
