import { Controller } from "stimulus"
import { selectize_selectAll, selectize_selectByCodes } from "../../echart-helper";

export default class extends Controller {
  static values = {
    allNoHongHe: Array,
    allNoHKHongHe: Array,
    creative: Array,
    creativeNoHongHe: Array,
    creativeNoHKHongHe: Array
  }
  static targets = [ "selectCtl" ]

  select_all() {
    const selectize_team_ids = this.selectCtlTarget.selectize;
    selectize_selectAll(selectize_team_ids);
    event.preventDefault();
  }

  select_creative() {
    const selectize_team_ids = this.selectCtlTarget.selectize;
    selectize_selectByCodes(selectize_team_ids, this.creativeValue)
    event.preventDefault();
  }

  select_creative_no_honghe() {
    const selectize_team_ids = this.selectCtlTarget.selectize;
    selectize_selectByCodes(selectize_team_ids, this.creativeNoHongHeValue)
    event.preventDefault();
  }

  select_creative_no_hk_honghe() {
    const selectize_team_ids = this.selectCtlTarget.selectize;
    selectize_selectByCodes(selectize_team_ids, this.creativeNoHKHongHeValue)
    event.preventDefault();
  }

  select_all_no_honghe() {
    const selectize_team_ids = this.selectCtlTarget.selectize;
    selectize_selectByCodes(selectize_team_ids, this.allNoHongHeValue)
    event.preventDefault();
  }

  select_all_no_hk_honghe() {
    const selectize_team_ids = this.selectCtlTarget.selectize;
    selectize_selectByCodes(selectize_team_ids, this.allNoHKHongHeValue)
    event.preventDefault();
  }
}
