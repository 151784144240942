import { Controller } from "stimulus"
import { selectize_selectAll } from "../../echart-helper";

export default class extends Controller {
  connect() {
    setTimeout(function(){
      const selectize_team_ids = document.getElementById('select-team-ids').selectize;
      selectize_team_ids.disable();
    }, 200);
  }

  change(event) {
    const need_sent_to_all = event.target.checked;
    const selectize_team_ids = document.getElementById('select-team-ids').selectize;
    if (need_sent_to_all) {
      selectize_team_ids.disable();
    } else {
      selectize_team_ids.enable();
    }
  }

  select_all() {
    const selectize_team_ids = document.getElementById('select-team-ids').selectize;
    selectize_team_ids.enable();
    selectize_selectAll(selectize_team_ids);
    document.getElementById('checkbox-sent-to-all').checked = false;
    event.preventDefault();
  }
}
