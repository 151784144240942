import { Controller } from "stimulus"

let customerActualPaymentChart;
let customerActualPaymentDatatable;
let customerActualPaymentTableFixedHeader;

export default class extends Controller {
  static values = { pageLength: Number }

  connect() {
    customerActualPaymentChart = echarts.init(document.getElementById('customer-actual-payment-chart'));

    const xAxis = JSON.parse(this.data.get("x_axis"));
    const top20s = JSON.parse(this.data.get("top20s"));
    const top20to50 = JSON.parse(this.data.get("top20to50s"));
    const gt50s = JSON.parse(this.data.get("gt50s"));
    const others = JSON.parse(this.data.get("others"));

    const option = {
      legend: {
        data: ['TOP 20 房企','TOP 20-50 房企','非 TOP 50 大客户','其他'],
        align: 'left'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      grid: {
        left: 65,
        right: 0,
        top: 48,
        bottom: 60
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
              pixelRatio: 2
          }
        }
      },
      xAxis: {
        data: xAxis,
        silent: true,
        axisLabel: {
          interval: 0
        },
        splitLine: {
            show: false
        }
      },
      yAxis: [{
        type: 'value',
        position: 'left',
        axisLabel: {
          formatter: '{value}百万'
        }
      }],
      series: [{
        name: 'TOP 20 房企',
        type: 'bar',
        stack: '生产合同额',
        data: top20s,
        barWidth: 68,
        label: {
          show: true,
          position: 'inside'
        }
      },{
        name: 'TOP 20-50 房企',
        type: 'bar',
        stack: '生产合同额',
        data: top20to50,
        barWidth: 68,
        label: {
          show: true,
          position: 'inside'
        }
      },{
        name: '非 TOP 50 大客户',
        type: 'bar',
        stack: '生产合同额',
        data: gt50s,
        barWidth: 68,
        label: {
          show: true,
          position: 'inside'
        }
      },{
        name: '其他',
        type: 'bar',
        stack: '生产合同额',
        data: others,
        barWidth: 68,
        label: {
          show: true,
          position: 'inside'
        }
      }]
    };

    function drill_down_customer_actual_payments_detail(params) {
      if (params.componentType === 'series') {
        if (params.seriesType === 'bar') {
          const sent_data = { year: params.name };

          const drill_down_url = "/report/customer_actual_payments/drill_down";

          $.ajax(drill_down_url, {
            data: sent_data,
            dataType: 'script'
          });
        }
      }
    }
    customerActualPaymentChart.setOption(option, false);
    customerActualPaymentChart.on('click', drill_down_customer_actual_payments_detail);

    setTimeout(() => {
      customerActualPaymentChart.resize();
    }, 200);

    const normalColumns = [
      {"data": "rank", bSortable: false},
      {"data": "customer_group", bSortable: false},
      {"data": "kerrey_trading_area_ranking"},
      {"data": "customer_ownership", bSortable: false},
      {"data": "receive_amount_last_year"},
      {"data": "receive_amount_this_year"},
      {"data": "group_receive_amount_last_year"},
      {"data": "group_receive_amount_this_year"},

      {"data": "receive_amount_3_months", bSortable: false},
      {"data": "receive_amount_1_year", bSortable: false},
      {"data": "receive_amount_1_year_repayment_rate", bSortable: false},
      {"data": "receive_amount_1_year_payback_rate", bSortable: false},
      {"data": "receive_amount_year_on_year_change_rate", bSortable: false},
    ];

    customerActualPaymentDatatable = $('#customer-actual-payments-datatable').DataTable({
      "processing": true,
      "serverSide": true,
      "pageLength": this.pageLengthValue,
      "dom": // original setting from node_modules/datatables.net-bs4/js/dataTables.bootstrap4.js
        "<'text-right'<'d-inline-block ml-5'l>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      "autoWidth": false,
      "ajax": $('#customer-actual-payments-datatable').data('source'),
      "pagingType": "full_numbers",
      "columns": normalColumns,
      "order": [[ 5, 'desc' ]],
      stateSave: true,
      stateSaveCallback: function(settings, data) {
          localStorage.setItem('DataTables_customer_actual_payments', JSON.stringify(data));
        },
      stateLoadCallback: function(settings) {
        return JSON.parse(localStorage.getItem('DataTables_customer_actual_payments'));
        }
    });

    function draw_dt() {
      const pageInfo = customerActualPaymentDatatable.page.info();
      customerActualPaymentDatatable.column(0, { page: 'current' }).nodes().each(function (cell, i) {
        cell.innerHTML = i + 1 + pageInfo.start;
      });
    }
    customerActualPaymentDatatable.on('draw.dt', draw_dt);

    customerActualPaymentTableFixedHeader = new $.fn.dataTable.FixedHeader(customerActualPaymentDatatable, {
      header: true,
      footer: false,
      headerOffset: 50,
      footerOffset: 0
    });
  }

  layout() {
    customerActualPaymentChart.resize();
  }

  disconnect() {
    customerActualPaymentChart.dispose();
    customerActualPaymentTableFixedHeader.destroy();
    customerActualPaymentDatatable.destroy();
  }
}
