import { Controller } from "stimulus"

let predictContractChart;
let predictContractChartPerWorker;

export default class extends Controller {
  connect() {
    predictContractChart = echarts.init(document.getElementById('predict-contract-chart'));
    predictContractChartPerWorker = echarts.init(document.getElementById('predict-contract-chart-per-worker'));

    const xAxisData = JSON.parse(this.data.get("x_axis"));
    const deptCodes = JSON.parse(this.data.get("dept_codes_as_options"));
    const contractConvert = JSON.parse(this.data.get("contract_convert"));
    const convertRealAmount = JSON.parse(this.data.get("convert_real_amount"));
    const convertRealAmountPerWorker = JSON.parse(this.data.get("convert_real_amount_per_worker"));
    const contractConvertTotals = JSON.parse(this.data.get("contract_convert_totals"));
    const option = {
      legend: {
        data: ['跟踪合同额（万元）','跟踪合同额（成功率小于80%）','流转中合同额（成功率等于80%）'],
        align: 'left'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      grid: {
        left: 70,
        right: 110,
        top: 50,
        bottom: 100
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
              pixelRatio: 2
          }
        }
      },
      xAxis: {
        data: xAxisData,
        silent: true,
        axisLabel: {
          interval: 0,
          rotate: -40,
          margin: 24
        },
        splitLine: {
            show: false
        }
      },
      yAxis: [{
        type: 'value',
        name: '跟踪合同额（万元）',
        position: 'left',
        axisLabel: {
          formatter: '{value}万'
        },
        min: 0
      }],
      series: [{
        name: '跟踪合同额（万元）',
        type: 'bar',
        barWidth: 20,
        barGap: '-100%',
        data: contractConvertTotals,
        itemStyle: {
          color: '#DDDDDD'
        },
        label: {
          show: true,
          color: '#353535',
          position: 'top'
        }
      },{
        name: '跟踪合同额（成功率小于80%）',
        type: 'bar',
        stack: '总量',
        data: contractConvert,
        barWidth: 20,
        itemStyle: {
          color: '#738496'
        },
        label: {
          show: true,
          position: 'insideTop'
        }
      },{
        name: '流转中合同额（成功率等于80%）',
        type: 'bar',
        stack: '总量',
        data: convertRealAmount,
        barWidth: 20,
        itemStyle: {
          color: '#334B5C'
        },
        label: {
          show: false
        }
      }]
    };

    const option_per_worker = {
      title: {
        text: '一线人均跟踪合同额',
        textStyle: {
          fontSize: 12,
        }
      },
      legend: {
          data: ['一线人均跟踪合同额（万元）'],
          align: 'left'
      },
      grid: {
        left: 50,
        right: 110,
        top: 60,
        bottom: 150
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
              pixelRatio: 2
          }
        }
      },
      xAxis: {
        data: xAxisData,
        silent: true,
        axisLabel: {
          interval: 0,
          rotate: -40
        },
        splitLine: {
            show: false
        }
      },
      yAxis: {
        axisLabel: {
          show: true,
          interval: 'auto',
          formatter: '{value}万'
        }
      },
      series: [{
        name: '一线人均跟踪合同额（万元）',
        type: 'bar',
        data: convertRealAmountPerWorker,
        color: '#738496',
        barMaxWidth: 48,
        label: {
          show: true,
          position: 'insideTop',
          fontWeight: 'bold',
          color: '#000000'
        }
      }]
    };

    function drill_down_contract_detail(params) {
      if (params.componentType === 'series') {
        if (params.seriesType === 'bar') {
          const department_name = xAxisData[params.dataIndex];
          const department_code = deptCodes[params.dataIndex];
          const month_name = $('#month_name').val();
          const sent_data = { department_name, department_code, month_name };
          let drill_down_url;
          switch (params.seriesName) {
            case '跟踪合同额（成功率小于80%）':
              drill_down_url = '/report/predict_contract/opportunity_detail_drill_down';
              break;
            case '流转中合同额（成功率等于80%）':
              drill_down_url = '/report/predict_contract/signing_detail_drill_down';
              break;
          }
          if (drill_down_url !== undefined) {
            $.ajax(drill_down_url, {
              data: sent_data,
              dataType: 'script'
            });
          }
        }
      }
    }

    predictContractChart.setOption(option, false);
    predictContractChart.on('click', drill_down_contract_detail);
    predictContractChartPerWorker.setOption(option_per_worker, false);


    setTimeout(() => {
      predictContractChart.resize();
      predictContractChartPerWorker.resize();
    }, 200);
  }

  layout() {
    predictContractChart.resize();
    predictContractChartPerWorker.resize();
  }

  disconnect() {
    predictContractChart.dispose();
    predictContractChartPerWorker.dispose();
  }
}
