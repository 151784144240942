import { Controller } from "stimulus"

let projectItemCodeSelect;

function selectValueChanged(value) {
  const project_item_code = $('#project-item-code-select').val();
  $.ajax({
    type: 'GET',
    dataType: 'script',
    url: '/ui/project_item_code_select/project_detail.js',
    data: { project_item_code }
  });
}

export default class extends Controller {
  static values = { url: String }

  connect() {
    const load_url = this.urlValue;
    projectItemCodeSelect = $('#project-item-code-select').selectize({
      plugins: ["remove_button"],
      respect_word_boundaries: false,
      valueField: "project_item_code",
      labelField: "project_item_name",
      searchField: ['project_item_name'],
      create: false,
      load: function (query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: `${load_url}?q=${encodeURIComponent(query)}`,
          type: "GET",
          error: function () {
            callback();
          },
          success: function (res) {
            callback(res.pm_project_items);
          },
        });
      },
    });

    projectItemCodeSelect.on('change', selectValueChanged);
  }

  disconnect() {
    projectItemCodeSelect.off('change', selectValueChanged);
  }
}
