import { Controller } from "stimulus"

let groupPredictContractChart;
let groupPredictContractPerStaffChart;
let groupPredictContractPerWorkerChart;

export default class extends Controller {
  connect() {
    groupPredictContractChart = echarts.init(document.getElementById('group-predict-contract-chart'));
    groupPredictContractPerStaffChart = echarts.init(document.getElementById('group-predict-contract-chart-per-staff'));
    groupPredictContractPerWorkerChart = echarts.init(document.getElementById('group-predict-contract-chart-per-worker'));

    const xAxisData = JSON.parse(this.data.get("x_axis"));
    const orgCodes = JSON.parse(this.data.get("org_codes"));
    const contractConvert = JSON.parse(this.data.get("contract_convert"));
    const convertRealAmount = JSON.parse(this.data.get("convert_real_amount"));
    const contractConvertTotals = JSON.parse(this.data.get("contract_convert_totals"));
    const contractConvertTotalsPerStaff = JSON.parse(this.data.get("contract_convert_totals_per_staff"));
    const contractConvertTotalsPerWorker = JSON.parse(this.data.get("contract_convert_totals_per_worker"));

    const option = {
      legend: {
        data: ['跟踪合同额（万元）','跟踪合同额（成功率小于80%）','流转中合同额（成功率等于80%）'],
        align: 'left'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      grid: {
        left: 70,
        right: 110,
        top: 50,
        bottom: 100
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
              pixelRatio: 2
          }
        }
      },
      xAxis: {
        data: xAxisData,
        silent: true,
        axisLabel: {
          interval: 0,
          rotate: -40,
          margin: 24
        },
        splitLine: {
            show: false
        }
      },
      yAxis: [{
        type: 'value',
        name: '跟踪合同额（万元）',
        position: 'left',
        axisLabel: {
          formatter: '{value}万'
        },
        min: 0
      }],
      series: [{
        name: '跟踪合同额（万元）',
        type: 'bar',
        barWidth: '30%',
        barGap: '-100%',
        data: contractConvertTotals,
        itemStyle: {
          color: '#DDDDDD'
        },
        label: {
          show: true,
          color: '#353535',
          position: 'top'
        }
      },{
        name: '跟踪合同额（成功率小于80%）',
        type: 'bar',
        stack: '总量',
        data: contractConvert,
        barWidth: 20,
        itemStyle: {
          color: '#738496'
        },
        label: {
          show: true,
          position: 'insideTop'
        }
      },{
        name: '流转中合同额（成功率等于80%）',
        type: 'bar',
        stack: '总量',
        data: convertRealAmount,
        barWidth: 20,
        itemStyle: {
          color: '#334B5C'
        },
        label: {
          show: false
        }
      }]
    };

    function drill_down_contract_detail(params) {
      if (params.componentType === 'series') {
        if (params.seriesType === 'bar') {
          const org_code = orgCodes[params.dataIndex];
          const month_name = $('#month_name').val();
          let url;
          url = '/report/predict_contract?view_deptcode_sum=true&org_code=' + org_code + '&month_name=' + encodeURIComponent(month_name);
          Turbolinks.visit(url);
        }
      }
    }

    const option_per_staff = {
      title: {
        text: '全员人均跟踪合同额',
        textStyle: {
          fontSize: 12,
        }
      },
      legend: {
          data: ['全员人均跟踪合同额（万元）'],
          align: 'left'
      },
      grid: {
        left: 50,
        right: 110,
        top: 60,
        bottom: 125
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
              pixelRatio: 2
          }
        }
      },
      xAxis: {
        data: xAxisData,
        silent: true,
        axisLabel: {
          interval: 0,
          rotate: -40
        },
        splitLine: {
            show: false
        }
      },
      yAxis: {
        axisLabel: {
          show: true,
          interval: 'auto',
          formatter: '{value}万'
        }
      },
      series: [{
        name: '全员人均跟踪合同额（万元）',
        type: 'bar',
        data: contractConvertTotalsPerStaff,
        barMaxWidth: 48,
        label: {
          show: true,
          position: 'insideTop',
          fontWeight: 'bold',
          color: '#000000'
        },
        itemStyle: {
          color: '#738496'
        }
      }]
    };

    const option_per_worker = {
      title: {
        text: '一线人均跟踪合同额',
        textStyle: {
          fontSize: 12,
        }
      },
      legend: {
          data: ['一线人均跟踪合同额（万元）'],
          align: 'left'
      },
      grid: {
        left: 50,
        right: 110,
        top: 60,
        bottom: 125
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
              pixelRatio: 2
          }
        }
      },
      xAxis: {
        data: xAxisData,
        silent: true,
        axisLabel: {
          interval: 0,
          rotate: -40
        },
        splitLine: {
            show: false
        }
      },
      yAxis: {
        axisLabel: {
          show: true,
          interval: 'auto',
          formatter: '{value}万'
        }
      },
      series: [{
        name: '一线人均跟踪合同额（万元）',
        type: 'bar',
        data: contractConvertTotalsPerWorker,
        barMaxWidth: 48,
        label: {
          show: true,
          position: 'insideTop',
          fontWeight: 'bold',
          color: '#000000'
        },
        itemStyle: {
          color: '#738496'
        }
      }]
    };

    groupPredictContractChart.setOption(option, false);
    groupPredictContractChart.on('click', drill_down_contract_detail);
    groupPredictContractPerStaffChart.setOption(option_per_staff, false);
    groupPredictContractPerWorkerChart.setOption(option_per_worker, false);

    setTimeout(() => {
      groupPredictContractChart.resize();
      groupPredictContractPerStaffChart.resize();
      groupPredictContractPerWorkerChart.resize();
    }, 200);
  }

  layout() {
    groupPredictContractChart.resize();
    groupPredictContractPerStaffChart.resize();
    groupPredictContractPerWorkerChart.resize();
  }

  disconnect() {
    groupPredictContractChart.dispose();
    groupPredictContractPerStaffChart.dispose();
    groupPredictContractPerWorkerChart.dispose();
  }
}
